import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from '../components/Homepage.js';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Tools and resources`}</h2>
    <Row className="resource-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard href="/guides/v2-migration" title="V2.0 Migration" actionIcon="arrowRight" aspectRatio="2:1" color="dark" mdxType="ResourceCard" />
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard href="/guides/configuration" title="Configuration guide" actionIcon="arrowRight" aspectRatio="2:1" color="dark" mdxType="ResourceCard" />
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard href="/contributions" title="Contributions" actionIcon="arrowRight" aspectRatio="2:1" color="dark" mdxType="ResourceCard" />
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard href="/demo" title="Demo" actionIcon="arrowRight" aspectRatio="2:1" color="dark" mdxType="ResourceCard" />
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard href="https://github.com/carbon-design-system/gatsby-theme-carbon" title="Github" actionIcon="arrowRight" aspectRatio="2:1" color="dark" mdxType="ResourceCard" />
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard href="/gallery" title="Gallery" actionIcon="arrowRight" aspectRatio="2:1" color="dark" mdxType="ResourceCard" />
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      